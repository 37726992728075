require("classlist-polyfill")
require("smoothscroll-polyfill").polyfill()
require("nodelist-foreach-polyfill")

// links
;(function () {
  document.querySelectorAll("a[href^='#']").forEach(function (link) {
    link.addEventListener("click", function (e) {
      // scroll to hash target
      e.preventDefault()
      var target = link.getAttribute("href")
      document
        .querySelector(target)
        .scrollIntoView({ behavior: "smooth", block: "start" })
    })
  })
})()

// carousel
;(function () {
  // state
  var index = 0
  var blocking = false
  var autoplay = null
  var images = document.querySelectorAll(".image-container")

  // feature image at index
  function featureImage() {
    images.forEach(function (image) {
      image.classList.remove("feature")
    })
    images[index].classList.add("feature", "load")
  }

  function featureNext() {
    index = index > images.length - 2 ? 0 : index + 1
    featureImage()
  }

  function featurePrev() {
    index = index = index > 0 ? index - 1 : images.length - 1
    featureImage()
  }

  function startAutoplay() {
    // cancel existing autoplay
    if (autoplay) {
      clearTimeout(autoplay)
    }

    // advance every 5 secs
    autoplay = setInterval(function () {
      featureNext()
    }, 4000)
  }

  // handle controls
  document.querySelectorAll(".image-control").forEach(function (button) {
    button.addEventListener("click", function () {
      // feature new image
      if (button.classList.contains("image-next")) {
        featureNext()
      } else {
        featurePrev()
      }

      // restart autoplay
      startAutoplay()
    })
  })

  startAutoplay()
})()

// scroll
;(function () {
  var images = document.querySelectorAll(".image-container")

  window.addEventListener("scroll", function () {
    // sticky progress bar
    document
      .querySelector(".scroll-progress-container")
      .classList.toggle("fixed", window.scrollY > window.innerHeight)

    // animate progress bar
    var prog =
      window.scrollY / (document.body.scrollHeight - window.innerHeight)
    document.querySelector(".scroll-progress").style.width = prog * 100 + "%"

    // lazy load images
    images.forEach(function (image, index) {
      if (image.classList.contains("load")) return
      if (image.getBoundingClientRect().top < 0) {
        image.classList.add("load")
      }
    })
  })
})()
